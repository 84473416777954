<template>
  <v-dialog
    v-model="dialog"
    class="width-dialog-sm-and-down dialog-box"
    width="430"
  >
    <div class="dialog-container width-dialog-sm-and-down">
      <div class="dialog-header">
        <h2 >Update ATM</h2>
      </div>
      <div
        class="dialog-body"
      >
        <div
          class="transparent sent-content">

          <el-form
            ref="form"
            :model="formData"
            :rules="rules"
          >
            <div >
              <div class="form-item">
                <span>Date </span>
                <el-form-item>
                  <v-date-picker
                    v-model="formData.date"
                    disabled
                  ></v-date-picker>
                </el-form-item>
              </div>
            </div>
            <div >
              <div class="form-item">
                <span>Handler Name</span>
                <el-form-item>
                  <v-input
                    v-model="formData.handle_name"></v-input>
                </el-form-item>
              </div>
              <div class="form-item ">
                <span>Bank Name</span>
                <el-form-item >
                  <v-select
                    v-model="formData.bank_no_id"
                    :list="globalOptions.banks"></v-select>
                </el-form-item>
              </div>
              <div class="form-item ">
                <span>Payer Name</span>
                <el-form-item >
                  <v-input
                    v-model="formData.account_no"
                    is-upper></v-input>
                </el-form-item>
              </div>
              <div class="form-item ">
                <span>Payer Mobile No. </span>
                <el-form-item >
                  <v-input
                    v-model="formData.account_mobile_no"
                    key-number
                    type="tel"
                    :maxlength="8"
                    prepend="+852"
                  ></v-input>
                </el-form-item>
              </div>
              <div class="form-item ">
                <span>Receipt</span>
                <el-form-item prop="policy_document_file_id">
                  <file-container
                    :edit="true"
                    :fileData="{
                      url: formData.receipt_file_url,
                      client_name: formData.receipt_client_name
                    }"
                    :otherData="{
                      idKey: 'receipt_file_id',
                      urlKey: 'receipt_file_url',
                      client_name:'receipt_client_name'
                    }"
                    @delete="handlerDeleteFile"
                    @uploadSuccess="handlerUploadSuccess" />
                </el-form-item>
              </div>
            </div>
          </el-form>
        </div>

      </div>
      <div
        class="dialog-footer"
        align="right">
        <div class="btn-container">
          <v-button
            size="small"
            class="button-theme-block"
            @click="dialog = false">Cancel
          </v-button>
          <v-button
            size="small"
            type="primary"
            class="button-theme"
            @click="handlerSave">Save
          </v-button>
        </div>
      </div>
    </div>
  </v-dialog>
</template>
<script>
import { mapActions, mapState } from 'vuex'
import MixinOptions from '@/mixins/options'
import {updateATM} from '@/api/order'
import MixinRules from '@/mixins/rules'

export default {
  name: 'updateAtmDialog',
  mixins: [MixinOptions,MixinRules],

  props:{
    form: {
      type: Object,
      default: () => ({})
    },
    orderNo:{
      type: [String,Number],
      default:'',//1// hire 2:issue
    },
  },
  data() {
    return {
      dialog:false,
      isSent:false,
      date:'',
      formData:{
        date:'',//1
        receipt_file_url:'',
        handle_name:'',
        receipt_file_id:'',
        receipt_client_name:'',
        account_no:'',
        bank_no_id:'',//2
        account_mobile_no:''
      }
    }
  },
  watch:{
    form: {
      deep: true,
      immediate: true,
      handler(val) {
        // let {payment} = val||{}
        Object.keys(this.formData).some(key => {
          if (key in val) {
            this.formData[key] = val[key]
          }
        })
        let {receipt_file={}} =val
        if(receipt_file){
          this.formData.receipt_file_id = receipt_file.file_id
          this.formData.receipt_file_url = receipt_file.url
          this.formData.receipt_client_name = receipt_file.client_name
        }
      }
    }
  },
  created() {

  },
  methods: {
    showDialog(){
      this.dialog = !this.dialog
    },
    // file-container 組件刪除文件
    handlerDeleteFile(otherData) {
      const { idKey, nameKey, urlKey,client_name } = otherData
      this.$set(this.formData, idKey, '')
      this.$set(this.formData, urlKey, '')
      this.$set(this.formData, nameKey, '')
      this.$set(this.formData, client_name, '')
    },
    handlerUploadSuccess({ data, result, otherData }) {
      const {  idKey,  urlKey,client_name } = otherData
      const { file_id,  url,path } = data
      this.$set(this.formData, idKey, file_id)
      this.$set(this.formData, urlKey, url)
      this.$set(this.formData, client_name, path)
      this.$refs['form'].clearValidate([idKey])
    },
    handlerSave(){
      this.network().updateATM()
    },
    network() {
      return {
        updateATM: async (params) => {
          let { data } = await updateATM({...this.formData,order_no:this.orderNo})
          if(data){
            this.$emit('updateCoverage')
            this.dialog =false
          }
        },
      }
    }
  }
}
</script>
<style lang="scss" scoped>
.dialog-box{
  width: 430px!important;
  ::v-deep .el-dialog{
    width: 430px;
  }
  .dialog-container {
    padding: 30px;
    color: #616266;
    width: 430px;
    .dialog-header {
      display: flex;
      justify-content: space-between;
      align-items: center;
      //margin: 0 -15px;
      //padding: 30px 30px 0 30px;
      //   border-bottom: 1px solid #dddfe7;
      h1 {
        font-size: 24px;
      }
    }
    .dialog-body{
      .sent-content{
        margin: 20px 0;
        color: #718096;
        .el-checkbox{
          margin-right: 10px;
        }
      }
      .form-item{
        margin: 0 0px 30px 0px;
        span{
          color: #718096;
        }
      }
    }

    .transparent{
      margin-bottom: 20px;
    }
  }
}
</style>
