<template>
  <section>
    <div class="flex-end button-margin">
      <v-button
        class="button-info"
        @click="handleCancel">
        <span>Cancel</span>
      </v-button>
      <v-button
        class="button-theme"
        @click="handlerValidate">
        <font-awesome-icon
          icon="print"
          class="margin-right"></font-awesome-icon>
        <span>Save</span>
      </v-button>
    </div>
    <p class="title">Set Payment</p>
    <select-payment
      ref="set-payment"
      :form="formData"
      :isLock="isLock"
      @getFormData="getSubFormData"
      @handlerApprove="handlerApprove"
    ></select-payment>
  </section>
</template>

<script>
import selectPayment from '@/views/components/selectPayment/selectPayment'
import {
  approvePayment,
  setOrderPayment
} from '@api/order'

export default {
  name: 'set-payment',
  components:{
    selectPayment
  },
  props:{
    form:{
      type:Object,
      default:()=>({})
    }
  },
  data(){
    return{
      formData:{
        payment:{}
      },
      subForm: {
        'set-payment':false,
      },
      approve:false,
    }
  },
  computed:{
    isEndorsement()
    {
      return this.form.policy_type ==203
    },
    isCancel(){
      return this.form.policy_type ==204
    },
    isLock(){
      let {premium={}} = this.form||{}
      return (premium.is_refund_order||premium.amount_received<=0)&&(this.isEndorsement||this.isCancel)
    },
  },
  watch:{
    form:{
      immediate:true,
      handler(val){
        this.formData = val
      }
    },
  },
  methods:{
    handlerApprove(){
      this.approve=true
      Object.keys(this.subForm).some(key => {
        this.subForm[key] = false
        this.$refs[key].submitForm(key)
      })
      if (Object.values(this.subForm).some(item => item === false)) {
        return
      }
      let {payment} = this.formData
      let {handle_name } = payment
      let name =handle_name
      this.$ConfirmBox({
        title: '',
        message: name+',are you confirmed that the payment is complete?'
      }).then(async () => {
        await this.handlerSetPayment(false)
        this.network().approvePayment()
      })
    },
    handleCancel(){
      this.$emit('update:reset_payment',false)
    },
    async handlerSetPayment() {
      // 獲取表單組件數據
      let order_no = this.$route.query.order_no

      Object.keys(this.subForm).some(key => {
        this.subForm[key] = false
        this.$refs[key].submitForm(key)
      })
      if (Object.values(this.subForm).some(item => item === false)) {
        return
      }
      let {payment,policy_id} = this.formData

      await  this.network().setOrderPayment({},false)
    },
    handlerValidate(refresh=true) {
      // 獲取表單組件數據
      let order_no = this.$route.query.order_no

      Object.keys(this.subForm).some(key => {
        this.subForm[key] = false
        this.$refs[key].submitForm(key)
      })
      if (Object.values(this.subForm).some(item => item === false)) {
        return
      }
      let {payment,policy_id} = this.formData
      let { type,last_name,first_name,handle_name } = payment

      if(type===2&&!this.approve)
      {
        this.$ConfirmBox({
          title: '',
          message: 'Approve Cheque later'
        }).then(() => {
          // if(this.isPayment)
          // {
          //   this.$router.push({
          //     path:'/policy/info',
          //     query:{
          //       policy_id
          //     }
          //   })
          // }else
          this.network().setOrderPayment(refresh)
        })
      }
      else if(type==1){
        let name = type==1?`${last_name} ${first_name}`:handle_name
        this.$ConfirmBox({
          title: '',
          message: 'SMS Payment will send to  '+name+',are you confirmed?'
        }).then(() => {
          this.network().setOrderPayment({},refresh)
        })
      } else{
        this.network().setOrderPayment({},refresh)

      }
    },
    getSubFormData(form, data) {
      this.subForm[form] = true
      this.formData = Object.assign(this.formData, data)
    },
    network() {
      return {
        setOrderPayment:async (params,refresh=true) => {
          let order_no = this.$route.query.order_no
          let {policy_id,policy_no,covernote_no,policy_document_id,covernote_document_id} = this.formData

          params = {...this.formData.payment,...{order_no:order_no},policy_no,covernote_no,covernote_document_id,policy_document_id}

          let { data } = await setOrderPayment(params)
          if(refresh)
          {
            this.$emit('update:reset_payment',false)
            this.$emit('refresh')
          }
        },
        approvePayment:async ()=>{
          let order_no = this.$route.query.order_no
          let {policy_id} = this.formData
          this.approve = false
          let { data } = await approvePayment({order_no:order_no})
          this.$emit('update:reset_payment',false)
          this.$emit('refresh')
        },
      }
    }
  }
}
</script>

<style scoped lang="scss">
.flex-end{
  display: flex;
  margin-bottom: 20px;
  justify-content: flex-end;
  .margin-right{
    margin-right: 5px;
  }
}
.title{
  color: #000;
  font-family: Inter;
  font-size: 36px;
  font-style: normal;
  font-weight: 700;
  line-height: 140%;
  margin-bottom: 30px;

}
</style>
