import axios from '@/jslib/axios'

export const createClaim = data => {
  return axios({
    method: 'post',
    url: '/admin/claim/create',
    data
  })
}
export const updateClaim = data => {
  return axios({
    method: 'post',
    url: '/admin/claim/update',
    data
  })
}
export const delClaim = params => {
  return axios({
    method: 'get',
    url: '/admin/claim/delete',
    params
  })
}
export const getPolicyClaimRecord = params => {
  return axios({
    method: 'get',
    url: '/admin/claim/policy/record',
    params
  })
}
