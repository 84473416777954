<template>
  <table class="table-list table-list--policy_premium">
    <tr class="th">
      <th>Document</th>
      <th >Created Date</th>
      <th >Send to Client</th>
    </tr>
    <tr>
      <td>
        <span class="table-span">
          <span>{{isEndorsement?'Endorsement':''}} Cover Note</span>
          <span class="fs-400">{{form.covernote_no}}</span>
          <div
            v-if="covernote_document.client_name"
            class="fs-14 card-doc">
            <p
              class="theme-second-blue hand pd-lr-0"
              @click="openFile(covernote_document)"
            >{{covernote_document.client_name}}</p>
            <p
              class="hand theme-second-blue flex-center-center"
              @click="handleUpoad(2,'',true)">
              <svg
                id="my-svg"
                width="16"
                class="mg-r-8"
                height="15"
                viewBox="0 0 16 15"
                fill="none"
                xmlns="http://www.w3.org/2000/svg">
                <path
                  d="M10.9922 3.04688L13.4531 5.50781C13.5625 5.61719 13.5625 5.78125 13.4531 5.89062L7.49219 11.8516L4.94922 12.125C4.62109 12.1797 4.32031 11.8789 4.375 11.5508L4.64844 9.00781L10.6094 3.04688C10.7188 2.9375 10.8828 2.9375 10.9922 3.04688ZM15.4219 2.41797C15.832 2.82812 15.832 3.51172 15.4219 3.92188L14.4648 4.87891C14.3555 4.98828 14.1914 4.98828 14.082 4.87891L11.6211 2.41797C11.5117 2.30859 11.5117 2.14453 11.6211 2.03516L12.5781 1.07812C12.9883 0.667969 13.6719 0.667969 14.082 1.07812L15.4219 2.41797ZM10.5 10.2383C10.5 10.1289 10.5273 10.0469 10.582 9.99219L11.6758 8.89844C11.8945 8.70703 12.25 8.84375 12.25 9.14453V13.4375C12.25 14.1758 11.6484 14.75 10.9375 14.75H1.3125C0.574219 14.75 0 14.1758 0 13.4375V3.8125C0 3.10156 0.574219 2.5 1.3125 2.5H9.10547C9.40625 2.5 9.54297 2.85547 9.35156 3.07422L8.25781 4.16797C8.20312 4.22266 8.12109 4.25 8.01172 4.25H1.75V13H10.5V10.2383Z"
                  fill="$primary"/>
              </svg>
              <span>Replace</span></p>
          </div>
          <span
            v-else
            class="none-client-name">Please Upload Cover Note</span>
        </span>

      </td>
      <td class="text-top">
        <template  v-if="covernote_document_upload.length>0">
          <span
            v-for="(item,index) in covernote_document_upload"
            :key="'covernote_document'+index"
            class="block block-span">
            <span>{{item.date}}</span>
            <span>{{item.created_by}}</span>
          </span>
        </template>
        <template v-else>
          <span>-</span>
        </template>
      </td>
      <td >
        <span
          v-for="(item,index) in covernote_document_send"
          :key="'covernote_document'+index"
          class="block block-span">
          <span>{{item.date}}</span>
          <span>{{item.type}}</span>
        </span>
        <span class="block">
          <v-button
            class="button-theme"
            @click="handleUpoad(2,!!covernote_document.client_name)">{{covernote_document.client_name?'Send':'Upload'}}</v-button>
        </span>
      </td>
    </tr>
    <template v-if="isCancel">
      <tr>
        <td>
          <span class="table-span">
            <span>Cancellation Letter</span>
            <div
              v-if="cancellation_letter_doc.client_name"
              class="fs-14 card-doc">
              <p
                class="theme-second-blue hand pd-lr-0"
                @click="openFile(cancellation_letter_doc)"
              >{{cancellation_letter_doc.client_name}}</p>
              <p
                class="hand theme-second-blue flex-center-center"
                @click="handleCancelUpoad(2,'',true)">
                <svg
                  id="my-svg"
                  width="16"
                  class="mg-r-8"
                  height="15"
                  viewBox="0 0 16 15"
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg">
                  <path
                    d="M10.9922 3.04688L13.4531 5.50781C13.5625 5.61719 13.5625 5.78125 13.4531 5.89062L7.49219 11.8516L4.94922 12.125C4.62109 12.1797 4.32031 11.8789 4.375 11.5508L4.64844 9.00781L10.6094 3.04688C10.7188 2.9375 10.8828 2.9375 10.9922 3.04688ZM15.4219 2.41797C15.832 2.82812 15.832 3.51172 15.4219 3.92188L14.4648 4.87891C14.3555 4.98828 14.1914 4.98828 14.082 4.87891L11.6211 2.41797C11.5117 2.30859 11.5117 2.14453 11.6211 2.03516L12.5781 1.07812C12.9883 0.667969 13.6719 0.667969 14.082 1.07812L15.4219 2.41797ZM10.5 10.2383C10.5 10.1289 10.5273 10.0469 10.582 9.99219L11.6758 8.89844C11.8945 8.70703 12.25 8.84375 12.25 9.14453V13.4375C12.25 14.1758 11.6484 14.75 10.9375 14.75H1.3125C0.574219 14.75 0 14.1758 0 13.4375V3.8125C0 3.10156 0.574219 2.5 1.3125 2.5H9.10547C9.40625 2.5 9.54297 2.85547 9.35156 3.07422L8.25781 4.16797C8.20312 4.22266 8.12109 4.25 8.01172 4.25H1.75V13H10.5V10.2383Z"
                    fill="$primary"/>
                </svg>
                <span>Replace</span></p>
            </div>
            <span
              v-else
              class="none-client-name">Please Upload Cancellation Letter</span>
          </span>

        </td>
        <td class="text-top">
          <template  v-if="cancellation_letter_upload.length>0">
            <span
              v-for="(item,index) in cancellation_letter_upload"
              :key="'cancellation_letter_upload'+index"
              class="block block-span">
              <span>{{item.date}}</span>
              <span>{{item.created_by}}</span>
            </span>
          </template>
          <template v-else>
            <span>-</span>
          </template>
        </td>
        <td >
          <!--        <span-->
          <!--          v-for="(item,index) in cancellation_letter_send"-->
          <!--          :key="'covernote_document'+index"-->
          <!--          class="block block-span">-->
          <!--          <span>{{item.date}}</span>-->
          <!--          <span>{{item.type}}</span>-->
          <!--        </span>-->
          <span class="block">
            <v-button
              v-if="!cancellation_letter_doc.client_name"
              class="button-theme"
              @click="handleCancelUpoad(2,!!cancellation_letter_doc.client_name)">{{cancellation_letter_doc.client_name?'Send':'Upload'}}</v-button>
          </span>
        </td>
      </tr>
      <tr>
        <td>
          <span class="table-span">
            <span>Refunded Document</span>
            <div
              v-if="cancellation_refund_doc.client_name"
              class="fs-14 card-doc">
              <p
                class="theme-second-blue hand pd-lr-0"
                @click="openFile(cancellation_refund_doc)"
              >{{cancellation_refund_doc.client_name}}</p>
              <p
                class="hand theme-second-blue flex-center-center"
                @click="handleCancelUpoad(4,'',true)">
                <svg
                  id="my-svg"
                  width="16"
                  class="mg-r-8"
                  height="15"
                  viewBox="0 0 16 15"
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg">
                  <path
                    d="M10.9922 3.04688L13.4531 5.50781C13.5625 5.61719 13.5625 5.78125 13.4531 5.89062L7.49219 11.8516L4.94922 12.125C4.62109 12.1797 4.32031 11.8789 4.375 11.5508L4.64844 9.00781L10.6094 3.04688C10.7188 2.9375 10.8828 2.9375 10.9922 3.04688ZM15.4219 2.41797C15.832 2.82812 15.832 3.51172 15.4219 3.92188L14.4648 4.87891C14.3555 4.98828 14.1914 4.98828 14.082 4.87891L11.6211 2.41797C11.5117 2.30859 11.5117 2.14453 11.6211 2.03516L12.5781 1.07812C12.9883 0.667969 13.6719 0.667969 14.082 1.07812L15.4219 2.41797ZM10.5 10.2383C10.5 10.1289 10.5273 10.0469 10.582 9.99219L11.6758 8.89844C11.8945 8.70703 12.25 8.84375 12.25 9.14453V13.4375C12.25 14.1758 11.6484 14.75 10.9375 14.75H1.3125C0.574219 14.75 0 14.1758 0 13.4375V3.8125C0 3.10156 0.574219 2.5 1.3125 2.5H9.10547C9.40625 2.5 9.54297 2.85547 9.35156 3.07422L8.25781 4.16797C8.20312 4.22266 8.12109 4.25 8.01172 4.25H1.75V13H10.5V10.2383Z"
                    fill="$primary"/>
                </svg>
                <span>Replace</span></p>
            </div>
            <span
              v-else
              class="none-client-name">Please Upload Cover Note</span>
          </span>

        </td>
        <td class="text-top">
          <template  v-if="cancellation_refunded_upload.length>0">
            <span
              v-for="(item,index) in cancellation_refunded_upload"
              :key="'cancellation_refunded_upload'+index"
              class="block block-span">
              <span>{{item.date}}</span>
              <span>{{item.created_by}}</span>
            </span>
          </template>
          <template v-else>
            <span>-</span>
          </template>
        </td>
        <td >
          <!--        <span-->
          <!--          v-for="(item,index) in cancellation_letter_send"-->
          <!--          :key="'covernote_document'+index"-->
          <!--          class="block block-span">-->
          <!--          <span>{{item.date}}</span>-->
          <!--          <span>{{item.type}}</span>-->
          <!--        </span>-->
          <span class="block">
            <v-button
              v-if="!cancellation_refund_doc.client_name"
              class="button-theme"
              @click="handleCancelUpoad(4,!!cancellation_refund_doc.client_name)">{{cancellation_refund_doc.client_name?'':'Upload'}}</v-button>
          </span>
        </td>
      </tr>
    </template>
    <tr>
      <td>
        <span class="table-span">
          <span v-if="!isCancel">{{isEndorsement?'Endorsement ':''}}Policy</span>
          <span v-else>Policy {{'- Cancellation'}}</span>
          <span class="fs-400">{{form.policy_no}}</span>

          <div
            v-if="policy_document.length>0"
            class="fs-14 card-doc">
            <p
              v-for="(item,index) in policy_document"
              :key="'policy_'+index"
              class="theme-second-blue hand pd-lr-0"
            >
              <span>{{item.name}}</span>
              <span
                class="theme-second-blue hand pd-lr-0"
                @click="openFile(item)">{{item.client_name}}</span>
            </p>
            <p
              class="hand theme-second-blue flex-center-center"
              @click="handleUpoad(1,'',true)">

              <svg
                id="my-svg"
                width="16"
                class="mg-r-8"
                height="15"
                viewBox="0 0 16 15"
                fill="none"
                xmlns="http://www.w3.org/2000/svg">
                <path
                  d="M10.9922 3.04688L13.4531 5.50781C13.5625 5.61719 13.5625 5.78125 13.4531 5.89062L7.49219 11.8516L4.94922 12.125C4.62109 12.1797 4.32031 11.8789 4.375 11.5508L4.64844 9.00781L10.6094 3.04688C10.7188 2.9375 10.8828 2.9375 10.9922 3.04688ZM15.4219 2.41797C15.832 2.82812 15.832 3.51172 15.4219 3.92188L14.4648 4.87891C14.3555 4.98828 14.1914 4.98828 14.082 4.87891L11.6211 2.41797C11.5117 2.30859 11.5117 2.14453 11.6211 2.03516L12.5781 1.07812C12.9883 0.667969 13.6719 0.667969 14.082 1.07812L15.4219 2.41797ZM10.5 10.2383C10.5 10.1289 10.5273 10.0469 10.582 9.99219L11.6758 8.89844C11.8945 8.70703 12.25 8.84375 12.25 9.14453V13.4375C12.25 14.1758 11.6484 14.75 10.9375 14.75H1.3125C0.574219 14.75 0 14.1758 0 13.4375V3.8125C0 3.10156 0.574219 2.5 1.3125 2.5H9.10547C9.40625 2.5 9.54297 2.85547 9.35156 3.07422L8.25781 4.16797C8.20312 4.22266 8.12109 4.25 8.01172 4.25H1.75V13H10.5V10.2383Z"
                  fill="$primary"/>
              </svg>
              <span>Replace</span></p>
          </div>
          <span
            v-else
            class="none-client-name">Please Upload Policy {{'- Cancellation'}}</span>
        </span>
        <!--        <span-->
        <!--          v-if="policy_document.client_name"-->
        <!--          class="theme-second-blue hand"-->
        <!--          @click="openFile(policy_document)"-->
        <!--        >{{policy_document.client_name}}</span>-->
      </td>
      <td class="text-top">
        <template v-if="policy_document_upload.length>0">
          <span
            v-for="(item,index) in policy_document_upload"
            :key="'policy_document'+index"
            class="block block-span">
            <span>{{item.date}}</span>
            <span>{{item.created_by}}</span>
          </span>
        </template>
        <span v-else>-</span>
      </td>
      <td >
        <span
          v-for="(item,index) in policy_document_send"
          :key="'policy_document'+index"
          class="block block-span">
          <span>{{item.date}}</span>
          <span>{{item.type}}</span>
        </span>
        <span class="block">
          <v-button
            class="button-theme"
            @click="handleUpoad(1,policy_document.length>0)">{{policy_document.length>0?'Send':'Upload'}}</v-button>
        </span>
      </td>
    </tr>
  </table>
</template>

<script>
import MixinFormat from '@/mixins/format'
import MixinOption from '@/mixins/options'

export default {
  name: 'documentInfo',
  mixins: [MixinFormat,MixinOption],
  props:{
    form:{
      type:Object,
      default:()=>({})
    }
  },
  computed:{
    isCompany(){
      return this.form.client_type==2
    },
    productId(){
      return this.form.product_id
    },
    isEndorsement(){
      return this.form.policy_type==203
    },
    isCancel(){
      return this.form.policy_type==204
    },
    covernote_document(){
      let {covernote_document} = this.form||{}
      return covernote_document||{}
    },
    cancellation_letter_doc(){
      let {cancellation_letter_file} = this.form||{}
      return cancellation_letter_file||{}
    },
    cancellation_refund_doc(){
      let {cancellation_refunded_file} = this.form||{}
      return cancellation_refunded_file||{}
    },
    policy_document(){
      return  this.new_policy_document.documents||[]
    },
    new_policy_document(){
      return  this.form.new_policy_documents||{}
    },
    policy_document_upload(){
      let {upload_history=[]} = this.new_policy_document||{}
      return upload_history||[]
    },
    policy_document_send(){
      let {send_history=[]} = this.new_policy_document||{}
      return send_history||[]
    },
    covernote_document_upload(){
      let {upload_history=[]} = this.covernote_document||{}
      return upload_history||[]
    },
    cancellation_letter_upload(){
      let {history=[]} = this.cancellation_letter_doc||{}
      return history||[]
    },
    cancellation_refunded_upload(){
      let {history=[]} = this.cancellation_refund_doc||{}
      return history||[]
    },
    covernote_document_send(){
      let {send_history=[]} = this.covernote_document||{}
      return send_history||[]
    },
    cancellation_letter_send(){
      let {send_history=[]} = this.cancellation_letter_doc||{}
      return send_history||[]
    },
    cancellation_refunded_send(){
      let {send_history=[]} = this.cancellation_refund_doc||{}
      return send_history||[]
    },
    documentList(){
      let list = {
        10:this.documentOptions,
        11:this.contractorDocumentOptions,
        12:this.travelDocumentOptions,
        13:this.travelDocumentOptions,
        14:this.employeeDocumentOptions,
        15:this.businessDocumentOptions,
        16:this.travelDocumentOptions,
        17:this.conEmployeeDocumentOptions,
        18:this.travelDocumentOptions
      }
      let {class_id,client_type} = this.form||{}
      let result = []
      this.globalOptions.document_type.some(item=>{

        if (item.class_id == class_id) {
          item.data.some(client=>{
            if(client.client_type==client_type)
              result = client.items
          })
        }
      })
      return result
      return list[this.productId]
    },
  },
  methods:{
    openFile(data) {
      const { url } = data
      if(url) {
        window.open(url, '_blank')
      }
    },
    handleUpoad(type,isUpload=false,isReplace=false){
      // let isUpload =JSON.stringify(doc)!='{}'
      this.$emit('upload',type,isReplace?false:isUpload,isReplace)
    },
    handleCancelUpoad(type,isUpload=false,isReplace=false){
      // let isUpload =JSON.stringify(doc)!='{}'
      this.$emit('cancelUpload',type,isReplace?false:isUpload,isReplace)
    },
  }
}
</script>

<style scoped lang="scss">
.theme-second-blue{
  margin-bottom: 8px;
}
.pd-lr-0{
  padding-left: 0;
  padding-right: 0;
}
.card-doc{
  span{
    font-size: 14px;
    font-style: normal;
    font-weight: 400;
    line-height: 150%;
  }
  p{
    font-size: 14px;
    font-style: normal;
    font-weight: 400;
    line-height: 150%;
  }
}
#my-svg{
  fill: $primary;
}
</style>
