<template>
  <v-dialog
    v-model="dialog"
    class="width-dialog-sm-and-down dialog-box"
    width="430"
  >
    <div class="dialog-container width-dialog-sm-and-down">
      <div class="dialog-header">
        <h2 v-if="type==1"> Date of Sending Insurer</h2>
        <h2 v-if="type==2"> Date of Policy Received</h2>
        <h2 v-if="type==3"> Sent Policy to Client</h2>
        <h2 v-if="type==4"> Hire Purchase Owner</h2>
      </div>
      <div
        class="dialog-body"
      >
        <div
          class="transparent sent-content">

          <el-form
            ref="form"
            :model="formData"
            :rules="rules"
          >
            <div v-if="type==1">
              <div class="form-item">
                <span>Date of Policy Issued</span>
                <el-form-item>
                  <v-date-picker
                    v-model="formData.issue_date"
                  ></v-date-picker>
                </el-form-item>
              </div>
            </div>
            <div v-if="type==2">
              <div class="form-item">
                <span>{{isEndorsement?'Endorsement':''}}  Policy Number</span>
                <el-form-item prop="policy_no">
                  <v-input
                    v-model="formData.policy_no"
                    is-upper
                    @change="handlerPolicyNumber"></v-input>
                </el-form-item>
              </div>
              <div class="form-item ">
                <span>{{isEndorsement?'Endorsement':''}}  Policy</span>
                <el-form-item prop="policy_document_file_id">
                  <file-container
                    :edit="true"
                    :fileData="{
                      url: formData.policy_document_file_url,
                      client_name: formData.policy_document_client_name
                    }"
                    :otherData="{
                      idKey: 'policy_document_id',
                      urlKey: 'policy_document_file_url',
                      client_name:'policy_document_client_name'
                    }"
                    @delete="handlerDeleteFile"
                    @uploadSuccess="handlerUploadSuccess" />
                </el-form-item>
              </div>
            </div>
            <div v-if="type==3">
              <div class="form-item">
                <span>Sent Policy to Client</span>
                <el-form-item prop="policy_no">
                  <v-date-picker
                    v-model="formData.policy_dispatch_date"
                  ></v-date-picker>
                </el-form-item>
              </div>
              <div class="form-item ">
                <span>Dispatch Method</span>
                <el-form-item prop="policy_document_file_id">
                  <v-select
                    v-model="formData.policy_dispatch_mathed_id"
                    :list="mathedOptions"></v-select>
                </el-form-item>
              </div>
            </div>
            <div v-if="type==4">
              <div class="form-item">
                <span>Date of Policy Issued</span>
                <el-form-item>
                  <v-date-picker
                    v-model="formData.policy_send_to_hire_purchase_owner_date"
                  ></v-date-picker>
                </el-form-item>
              </div>
            </div>
          </el-form>
        </div>

      </div>
      <div
        class="dialog-footer"
        align="right">
        <div class="btn-container">
          <v-button
            size="small"
            class="button-theme-block"
            @click="dialog = false">Cancel
          </v-button>
          <v-button
            size="small"
            type="primary"
            class="button-theme"
            @click="handlerSave">Save
          </v-button>
        </div>
      </div>
    </div>
  </v-dialog>
</template>
<script>
import { mapActions, mapState } from 'vuex'
import MixinOptions from '@/mixins/options'
import {setDocumentStatus} from '@/api/order'
import MixinRules from '@/mixins/rules'
import {checkPolicyNumber} from '@api/policy'

export default {
  name: 'policyStatusDialog',
  mixins: [MixinOptions,MixinRules],

  props:{
    type:{
      type: Number,
      default:0,//1// hire 2:issue
    },
    orderNo:{
      type: [String,Number],
      default:'',//1// hire 2:issue
    },
    form: {
      type: Object,
      default: () => ({})
    },
  },
  data() {
    return {
      dialog:false,
      isSent:false,
      date:'',
      formData:{
        issue_date:'',//1
        policy_document_id:'',//2
        policy_document_client_name:'',
        policy_document_file_url:'',
        policy_no:'',//2
        policy_dispatch_date:'',//3
        policy_dispatch_mathed_id:'',//3
        policy_send_to_hire_purchase_owner_date:''//4
      }
    }
  },
  computed:{
    isEndorsement(){
      return this.form.policy_type==203
    }
  },
  watch:{
    form: {
      deep: true,
      immediate: true,
      handler(val) {
        Object.keys(this.formData).some(key => {
          if (key in val) {
            this.formData[key] = val[key]
          }
        })
        let {policy_document={}} =val
        if(policy_document){
          let {url,file_id,client_name}=policy_document
          this.$set(this.formData,'policy_document_file_url',url)
          this.$set(this.formData,'policy_document_client_name',client_name)
          this.$set(this.formData,'policy_document_id',file_id)
        }
      }
    }
  },
  created() {

  },
  methods: {
    handlerPolicyNumber(){
      if(this.isEndorsement)
        return
      if(this.formData.policy_no)
        this.network().checkPolicyNumber()
    },
    showReferDialog(){
      this.dialog = !this.dialog
    },
    // file-container 組件刪除文件
    handlerDeleteFile(otherData) {
      const { idKey, nameKey, urlKey,client_name } = otherData
      this.$set(this.formData, idKey, '')
      this.$set(this.formData, urlKey, '')
      this.$set(this.formData, nameKey, '')
      this.$set(this.formData, client_name, '')
    },
    handlerUploadSuccess({ data, result, otherData }) {
      const {  idKey,  urlKey,client_name } = otherData
      const { file_id,  url,path } = data
      this.$set(this.formData, idKey, file_id)
      this.$set(this.formData, urlKey, url)
      this.$set(this.formData, client_name, path)
      this.$refs['form'].clearValidate([idKey])
    },
    handlerSave(){
      let {issue_date,policy_document_id,policy_no,policy_dispatch_date, policy_dispatch_mathed_id,policy_send_to_hire_purchase_owner_date} =this.formData
      let params = {
        1:{
          issue_date
        },
        2:{
          policy_document_id,
          policy_no
        },
        3:{
          policy_dispatch_date,
          policy_dispatch_mathed_id
        },
        4:{
          policy_send_to_hire_purchase_owner_date
        }
      }
      this.network().setDocumentStatus(params[this.type])
    },
    network() {
      return {
        setDocumentStatus: async (params) => {
          let { data } = await setDocumentStatus({...params,order_no:this.orderNo,step:this.type})
          if(data){
            this.$emit('updateCoverage')
            this.dialog =false
          }
        },
        checkPolicyNumber: async () => {
          let params = {
            policy_no:this.formData.policy_no,
            company_id:this.form.company_id,
          }
          let { data } = await checkPolicyNumber(params)
          if(data.repeated)
            this.$ConfirmBox({
              title: '',
              message: '“Policy Number” has been stored in our system. Do you want to continue?',
              confirmBtn:'Yes',
              cancelBtn:'No',
              isCancel:true,
            }).then(() => {
            })
        },
      }
    }
  }
}
</script>
<style lang="scss" scoped>
.dialog-box{
  width: 430px!important;
  ::v-deep .el-dialog{
    width: 430px;
  }
.dialog-container {
  padding: 30px;
  color: #616266;
  width: 430px;
  .dialog-header {
    display: flex;
    justify-content: space-between;
    align-items: center;
    //margin: 0 -15px;
    //padding: 30px 30px 0 30px;
    //   border-bottom: 1px solid #dddfe7;
    h1 {
      font-size: 24px;
    }
  }
  .dialog-body{
    .sent-content{
      margin: 20px 0;
      color: #718096;
      .el-checkbox{
        margin-right: 10px;
      }
    }
    .form-item{
      margin: 0 0px 30px 0px;
      span{
        color: #718096;
      }
    }
  }

  .transparent{
    margin-bottom: 20px;
  }
}
}
</style>
