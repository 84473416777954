<template>
  <section>
    <div class="claim-body">
      <div
        v-for="(item,index) in list"
        :key="index"
        class="cliam-box">
        <div class="flex-justify title-content">
          <span class="title">Accident Date {{item.accident_date}}</span>
          <v-button
            v-if="isEdit"
            class="button-theme"
            @click="handleEditClaim(item)"
          >Edit Claim</v-button>
        </div>
        <span class="content-gray">
          Policy No. {{item.policy_no||'-'}}
        </span>
        <span class="status">
          <span>
            <font-awesome-icon
              class="icon"
              icon="level-up-alt"></font-awesome-icon>
            <span
              class="status-box"
              :class="{'status-box__processing':item.status==1,'status-box__paid':item.status==3||item.status==2}"
            >{{getOptionName('claimStatusOptions',item.status)}}</span>
            <template v-if="item.status==3">
              <span class="mg-r-8 content-gray">Claimed Amount</span>
              <span class="content-gray">{{item.claimed_amount|formatMoney(2,true)}}</span>
            </template>
          </span>
        </span>
        <p class="content">{{item.accident_desc}}</p>
        <div
          class="table mg-b-10 accordion-item"
          :class="{'active':open(item.claim_id)}"
        >
          <div
            class="tr">
            <span>Contact Person</span>
            <span>
              <span class="block">{{item.contact_name}}</span>
              <span>{{item.contact_mobile}}</span>
            </span>
          </div>
          <div
            class="tr">
            <span>Related Document</span>
            <span>
              <span
                v-for="(item_b,b_index) in item.documents"
                :key="'document_'+b_index"
                class="theme-second-blue  block hand"
                @click="openFile(item_b)">
                {{item_b.client_name}}
              </span>
            </span>
          </div>
        </div>
        <div class="flex-justify">
          <span class="flex">
            <span class=" content-gray">
              <span class="mg-r-4">Created Date</span>
              <span>{{item.created_at}}</span>
            </span>
            <span class=" content-gray mg-l-20">
              <span  class="mg-r-4">Updated Date</span>
              <span>{{item.updated_at}}</span>
            </span>
          </span>
          <span
            @click="handleShowDetail(item.claim_id)"><font-awesome-icon
            class="theme-blue transfrom"
            :class="{'transfrom-90':open(item.claim_id)}"
            icon="chevron-down"/></span>
        </div>
      </div>
    </div>
  </section>
</template>

<script>
import MixinOptions from '@/mixins/options'
import MixinFormat from '@/mixins/format'
import {mapActions} from 'vuex'

export default {
  name: 'claimListContent',
  mixins: [MixinOptions,MixinFormat],
  props:{
    list:{
      type:Array,
      default:()=>([])
    },
    isEdit:{
      type:Boolean,
      default:true
    }
  },
  data(){
    return{
      activeTable:'',
      showDetail:[]
    }
  },
  computed:{
    open(){
      return (claim_id)=>{
        return this.showDetail.includes(claim_id)
      }
    }
  },
  methods:{
    ...mapActions('claim', ['setClaimDetail']),
    handleEditClaim(item){
      this.setClaimDetail(item)
      this.$router.push(`/edit/claim/${item.claim_id}`)
    },
    handleShowDetail(claim_id){
      let isInclued = this.showDetail.includes(claim_id)
      if(!isInclued){
        this.showDetail.push(claim_id)
        return
      }
      this.showDetail.filter((item,index)=>{
        if(item==claim_id){
          this.showDetail.splice(index,1)
        }
      })
    }
  }
}
</script>

<style scoped lang="scss">
.claim-body{
  .mg-b-10{
    margin-bottom: 10px;
  }
  .cliam-box{
    width: 100%;
    padding: 20px;
    flex-direction: column;
    align-items: flex-start;
    border: 1px solid #CBD5E0;
    background: #FFF;
    margin-bottom: 10px;
    .title-content{
      margin-bottom: 10px;
      .title{
        color: $gray;
        font-size: 14px;
        font-style: normal;
        font-weight: 700;
        line-height: 150%;
        display: block;
      }
    }

    .status{
      display: block;
      margin-top: 10px;
      .icon{
        transform: rotate(90deg);
        color: #CBD5E0;
        font-size: 16px;
        font-style: normal;
        font-weight: 900;
        line-height: 150%;
        margin-right: 10px;
        display: inline-block;
        margin-left: 4px;
      }
      .status-box{
        padding: 1px 5px;
        font-family: Inter;
        font-size: 12px;
        font-style: normal;
        font-weight: 400;
        line-height: 18px; /* 150% */
        letter-spacing: 0.4px;
        border-radius: 4px;
        margin-right: 10px;
        &__processing{
          border: 1px solid  #718096;
          color: #718096;
        }
        &__paid{
          border: 1px solid  #10B981;
          color: #10B981;
        }
      }
    }
    .content{
      font-size: 14px;
      font-style: normal;
      font-weight: 400;
      line-height: 150%;
      color: $gray;
      margin-bottom: 11.5px;
      margin-top: 10px;
      display: -webkit-box;
      -webkit-box-orient: vertical;
      word-break: break-word;
      overflow: hidden;
      -webkit-line-clamp: 2;
      text-overflow: ellipsis;
    }
    .content-gray{
      color: $disable;
      font-size: 14px;
      font-style: normal;
      font-weight: 400;
      line-height: 150%;
    }
    .accordion-item{
      max-height:0;
      overflow: hidden;
      transition: max-height 0.5s ease;
      &.active{
        max-height: 500px;
      }
    }
    .transfrom{
      transform: rotate(0deg);
      transition: transform 0.5s ease;
    }
    .transfrom-90{
      transform: rotate(-180deg);
      transition: transform 0.5s ease;
    }
  }

}
</style>
