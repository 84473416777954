<template>
  <table class="table-list table-list--policy_premium">
    <tr class="th">
      <th>Document</th>
      <th >Created Date</th>
      <th >Generate</th>
    </tr>
    <tr>
      <td>
        <span class="table-span">
          <span>Application</span>
          <div
            class="card-doc">
            <p
              v-if="proposalPath==0"
              class="theme-second-blue hand"
              @click="openFile(proposal_document)">{{proposal_document.client_name||'-'}}</p>
            <p
              v-else
              class="text-center fs-14 blue icon-loading-size">
              <i class="el-icon-loading"></i>
              <span class="update-text theme-second-blue">Updating</span>
            </p>
          </div>
        </span>

      </td>
      <td class="text-top">
        <template  v-if="proposal_document_upload.length>0">
          <span
            v-for="(item,index) in proposal_document_upload"
            :key="'covernote_document'+index"
            class="block block-span">
            <span>{{item.date}}</span>
            <span>{{item.created_by}}</span>
          </span>
        </template>
        <template v-else>
          <span>-</span>
        </template>
      </td>
      <td >
        <span class="block">
          <v-button
            class="button-theme"
            @click="handleGenerate">
            <span
            >
              <span>Generate</span>
            </span>
          </v-button>
          <!--          <v-button-->
          <!--            class="button-theme"-->
          <!--            @click="handleGenerate">Generate</v-button>-->
        </span>
      </td>
    </tr>
  </table>
</template>

<script>
import MixinFormat from '@/mixins/format'
import MixinOption from '@/mixins/options'
import {generateOrder} from '@api/order'

export default {
  name: 'applicationDocaumentInfo',
  mixins: [MixinFormat,MixinOption],
  props:{
    form:{
      type:Object,
      default:()=>({})
    },
    loadingData:{
      type:Object,
      default:()=>({})
    },
    proposalPath:{
      type:Number,
      default:0
    }
  },
  data(){
    return{
      loading:false,
    }
  },
  computed:{
    loadingDone(){
      return (name) =>{
        if(!this.order_no) return true
        return this.loadingData[name]==0
      }
    },
    isEndorsement(){
      return this.form.policy_type==203
    },
    proposal_document(){
      let {documents} = this.loadingData||{}
      let {proposal_document} = documents||{}
      return  proposal_document||{}
    },
    proposal_document_upload(){
      let {history=[]} = this.proposal_document||{}
      return history||[]
    },
    proposal_document_send(){
      let {send_history=[]} = this.proposal_document||{}
      return send_history||[]
    },
  },
  methods:{
    openFile(data) {
      const { url } = data
      if(url) {
        window.open(url, '_blank')
      }
    },
    handleGenerate(){
      this.loading = true
      this.network().generateOrder()
    },
    handleUpoad(type,doc,isReplace=false){
      let isUpload =JSON.stringify(doc)!='{}'
      this.$emit('upload',type,isReplace?false:isUpload,isReplace)
    },
    network() {
      return {
        generateOrder: async () => {
          let order_no = this.$route.query.order_no
          let {data} = await generateOrder({order_no: order_no})
          this.loading = false
          this.$emit('handleUpdateStatus')
        },
      }
    }
  }
}
</script>

<style scoped lang="scss">
.theme-second-blue{
  margin-bottom: 8px;
}
.pd-lr-0{
  padding-left: 0;
  padding-right: 0;
}
.card-doc{
  span{
    font-size: 14px;
    font-style: normal;
    font-weight: 400;
    line-height: 150%;
  }
  p{
    font-size: 14px;
    font-style: normal;
    font-weight: 400;
    line-height: 150%;
  }
}
#my-svg{
  fill: $primary;
}
.icon-loading-size{
  height: auto!important;
  justify-content:flex-start!important;
  font-size: 14px!important;
  line-height: 14px;
}
.update-text{
  //font-size: 12px!important;
  margin-bottom: 0px;
  margin-left: 4px;
  color: $gray;
  padding: 0;
  text-align: left;
}
.card-doc{
  span{
    font-size: 14px;
    font-style: normal;
    font-weight: 400;
    line-height: 150%;
  }
  p{
    font-size: 14px;
    font-style: normal;
    font-weight: 400;
    line-height: 150%;
    padding-top: 10px;
  }
}
.table-list{
  tr{
    td{
      vertical-align: text-top;
    }
  }
}
</style>
