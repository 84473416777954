var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-dialog',{staticClass:"width-dialog-sm-and-down dialog-box",attrs:{"width":"430"},model:{value:(_vm.dialog),callback:function ($$v) {_vm.dialog=$$v},expression:"dialog"}},[_c('div',{staticClass:"dialog-container width-dialog-sm-and-down"},[_c('div',{staticClass:"dialog-header"},[(_vm.type==1)?_c('h2',[_vm._v(" Date of Sending Insurer")]):_vm._e(),(_vm.type==2)?_c('h2',[_vm._v(" Date of Policy Received")]):_vm._e(),(_vm.type==3)?_c('h2',[_vm._v(" Sent Policy to Client")]):_vm._e(),(_vm.type==4)?_c('h2',[_vm._v(" Hire Purchase Owner")]):_vm._e()]),_c('div',{staticClass:"dialog-body"},[_c('div',{staticClass:"transparent sent-content"},[_c('el-form',{ref:"form",attrs:{"model":_vm.formData,"rules":_vm.rules}},[(_vm.type==1)?_c('div',[_c('div',{staticClass:"form-item"},[_c('span',[_vm._v("Date of Policy Issued")]),_c('el-form-item',[_c('v-date-picker',{model:{value:(_vm.formData.issue_date),callback:function ($$v) {_vm.$set(_vm.formData, "issue_date", $$v)},expression:"formData.issue_date"}})],1)],1)]):_vm._e(),(_vm.type==2)?_c('div',[_c('div',{staticClass:"form-item"},[_c('span',[_vm._v(_vm._s(_vm.isEndorsement?'Endorsement':'')+" Policy Number")]),_c('el-form-item',{attrs:{"prop":"policy_no"}},[_c('v-input',{attrs:{"is-upper":""},on:{"change":_vm.handlerPolicyNumber},model:{value:(_vm.formData.policy_no),callback:function ($$v) {_vm.$set(_vm.formData, "policy_no", $$v)},expression:"formData.policy_no"}})],1)],1),_c('div',{staticClass:"form-item "},[_c('span',[_vm._v(_vm._s(_vm.isEndorsement?'Endorsement':'')+" Policy")]),_c('el-form-item',{attrs:{"prop":"policy_document_file_id"}},[_c('file-container',{attrs:{"edit":true,"fileData":{
                    url: _vm.formData.policy_document_file_url,
                    client_name: _vm.formData.policy_document_client_name
                  },"otherData":{
                    idKey: 'policy_document_id',
                    urlKey: 'policy_document_file_url',
                    client_name:'policy_document_client_name'
                  }},on:{"delete":_vm.handlerDeleteFile,"uploadSuccess":_vm.handlerUploadSuccess}})],1)],1)]):_vm._e(),(_vm.type==3)?_c('div',[_c('div',{staticClass:"form-item"},[_c('span',[_vm._v("Sent Policy to Client")]),_c('el-form-item',{attrs:{"prop":"policy_no"}},[_c('v-date-picker',{model:{value:(_vm.formData.policy_dispatch_date),callback:function ($$v) {_vm.$set(_vm.formData, "policy_dispatch_date", $$v)},expression:"formData.policy_dispatch_date"}})],1)],1),_c('div',{staticClass:"form-item "},[_c('span',[_vm._v("Dispatch Method")]),_c('el-form-item',{attrs:{"prop":"policy_document_file_id"}},[_c('v-select',{attrs:{"list":_vm.mathedOptions},model:{value:(_vm.formData.policy_dispatch_mathed_id),callback:function ($$v) {_vm.$set(_vm.formData, "policy_dispatch_mathed_id", $$v)},expression:"formData.policy_dispatch_mathed_id"}})],1)],1)]):_vm._e(),(_vm.type==4)?_c('div',[_c('div',{staticClass:"form-item"},[_c('span',[_vm._v("Date of Policy Issued")]),_c('el-form-item',[_c('v-date-picker',{model:{value:(_vm.formData.policy_send_to_hire_purchase_owner_date),callback:function ($$v) {_vm.$set(_vm.formData, "policy_send_to_hire_purchase_owner_date", $$v)},expression:"formData.policy_send_to_hire_purchase_owner_date"}})],1)],1)]):_vm._e()])],1)]),_c('div',{staticClass:"dialog-footer",attrs:{"align":"right"}},[_c('div',{staticClass:"btn-container"},[_c('v-button',{staticClass:"button-theme-block",attrs:{"size":"small"},on:{"click":function($event){_vm.dialog = false}}},[_vm._v("Cancel ")]),_c('v-button',{staticClass:"button-theme",attrs:{"size":"small","type":"primary"},on:{"click":_vm.handlerSave}},[_vm._v("Save ")])],1)])])])}
var staticRenderFns = []

export { render, staticRenderFns }