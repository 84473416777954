var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('section',[_c('v-dialog',{staticClass:"width-dialog-sm-and-down dialog-box",attrs:{"width":"430"},model:{value:(_vm.dialog),callback:function ($$v) {_vm.dialog=$$v},expression:"dialog"}},[_c('div',{staticClass:"dialog-container width-dialog-sm-and-down"},[_c('div',{staticClass:"dialog-header"},[(_vm.uploadType!==3)?_c('h2',[_vm._v("Upload "+_vm._s(_vm.isPolicy?'Policy':' '))]):_vm._e()]),_c('div',{staticClass:"dialog-body"},[_c('div',{staticClass:"transparent ",class:{'sent-content':_vm.uploadType!=3}},[_c('el-form',{ref:"form",attrs:{"model":_vm.formData,"rules":_vm.rules}},[(_vm.uploadType!=3)?_c('div',[(_vm.isPolicy)?[_c('div',{staticClass:"form-item"},[_c('span',[_vm._v("Policy Number")]),_c('el-form-item',{ref:"upload_no",attrs:{"prop":"upload_no","rules":_vm.rules.input}},[_c('v-input',{model:{value:(_vm.formData.upload_no),callback:function ($$v) {_vm.$set(_vm.formData, "upload_no", $$v)},expression:"formData.upload_no"}})],1)],1),_c('div',{staticClass:"form-item"},[_c('el-form-item',{ref:"policy_document_type",attrs:{"prop":"policy_document_type","rules":_vm.rules.select}},[_c('v-radio-group',{attrs:{"list":_vm.policyUploadTypeOptions},model:{value:(_vm.formData.policy_document_type),callback:function ($$v) {_vm.$set(_vm.formData, "policy_document_type", $$v)},expression:"formData.policy_document_type"}})],1)],1)]:_vm._e(),(!_vm.isPolicy||_vm.isPolicy&&_vm.formData.policy_document_type!=2)?_c('div',{staticClass:"form-item "},[(_vm.isPolicy)?_c('span',[_vm._v("Policy")]):_vm._e(),(_vm.isCancelLetter)?_c('span',[_vm._v("Cancellation Letter")]):_vm._e(),(_vm.isRefunded)?_c('span',[_vm._v("Refunded Document")]):_vm._e(),_c('el-form-item',{attrs:{"prop":"upload_file_id","rules":_vm.rules.upload}},[_c('file-container-card',{attrs:{"edit":true,"is-dialog":"","fileData":_vm.formData.upload_file,"otherData":{
                      idKey: 'upload_file_id',
                      urlKey: 'upload_file_url',
                      client_name:'upload_file_client_name',
                      nameKey:'upload_file'
                    }},on:{"view":_vm.handleView,"delete":_vm.handlerDeleteFile,"uploadSuccess":_vm.handlerUploadSuccess}})],1)],1):_vm._e(),(_vm.isPolicy&&_vm.formData.policy_document_type==2)?[_c('div',{staticClass:"form-item "},[_c('span',[_vm._v("CI")]),_c('el-form-item',{attrs:{"prop":"policy_ci_document_id","rules":_vm.rules.upload}},[_c('file-container-card',{attrs:{"edit":true,"is-dialog":"","fileData":_vm.formData.policy_ci_document,"otherData":{
                        idKey: 'policy_ci_document_id',
                        urlKey: 'policy_ci_document_url',
                        client_name:'policy_ci_document_client_name',
                        nameKey:'policy_ci_document'
                      }},on:{"view":_vm.handleView,"delete":_vm.handlerDeleteFile,"uploadSuccess":_vm.handlerUploadSuccess}})],1)],1),_c('div',{staticClass:"form-item "},[_c('span',[_vm._v("Schedule")]),_c('el-form-item',{attrs:{"prop":"policy_schedule_document_id","rules":_vm.rules.upload}},[_c('file-container-card',{attrs:{"edit":true,"is-dialog":"","fileData":_vm.formData.policy_schedule_document,"otherData":{
                        idKey: 'policy_schedule_document_id',
                        urlKey: 'policy_schedule_document_url',
                        client_name:'policy_schedule_document_client_name',
                        nameKey:'policy_schedule_document'
                      }},on:{"view":_vm.handleView,"delete":_vm.handlerDeleteFile,"uploadSuccess":_vm.handlerUploadSuccess}})],1)],1)]:_vm._e()],2):_c('div',[_c('div',{staticClass:"form-item"},[_c('span',{staticClass:"black-color"},[_vm._v("Full Set Doc. Received?")]),_c('div',{staticClass:"car-type-radio"},[_c('el-form-item',{ref:"documents_received",attrs:{"rules":_vm.rules.select,"prop":"documents_received"}},[_c('v-radio-group',{attrs:{"border":true,"list":_vm.yesNoOptions},model:{value:(_vm.formData.documents_received),callback:function ($$v) {_vm.$set(_vm.formData, "documents_received", $$v)},expression:"formData.documents_received"}})],1)],1)])])])],1)]),_c('div',{staticClass:"dialog-footer",attrs:{"align":"right"}},[_c('div',{staticClass:"btn-container"},[_c('v-button',{staticClass:"button-theme-block",attrs:{"size":"small"},on:{"click":_vm.handleCancel}},[_vm._v("Cancel ")]),_c('v-button',{staticClass:"button-theme",attrs:{"size":"small","type":"primary"},on:{"click":_vm.handlerSave}},[_vm._v("Confirm ")])],1)])])]),_c('v-dialog',{staticClass:"view-dialog",attrs:{"showClose":"","fullscreen":""},model:{value:(_vm.isOpen),callback:function ($$v) {_vm.isOpen=$$v},expression:"isOpen"}},[_c('embed',{attrs:{"src":_vm.fileUrl,"width":"100%","height":"100%","type":"application/pdf"}})])],1)}
var staticRenderFns = []

export { render, staticRenderFns }