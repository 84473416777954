<template>
  <section>
    <div  class="flex-justify flex flex-content">
      <div class="flex-cloumn">
        <span>Unsettled Claim:</span>
        <span class="number">{{claimNumber}}</span>
      </div>
      <div class="flex-cloumn flex-button">
        <div class="flex">
          <v-button
            class="button-theme"
            @click="handleAddClaim"
          >Add Claim</v-button>
        </div>
      </div>
    </div>
    <cliam-list :list="tableData"></cliam-list>
  </section>
</template>

<script>
import MixinOptions from '@/mixins/options'
import MixinFormat from '@/mixins/format'
import CliamList from '@/views/claim/componment/claim-list-content'
import {getPolicyClaimRecord} from '@api/claim'
import {mapActions} from 'vuex'

export default {
  name: 'ClaimIndex',
  components: {CliamList},
  mixins: [MixinOptions,MixinFormat],
  props:{
    claimNumber:{
      type:Number,
      default:0
    }
  },
  data(){
    return{
      summary:{
        policy_num:0
      },
      tableData:[],
      pageConfig: {
        //  分頁配置
        currentPage: 1,
        total: 10,
        to:'',
        per_page:1,
        size:20
      },
    }
  },
  computed:{
    order_no(){
      let {order_no} = this.$route.query||{}
      return order_no
    }
  },
  mounted() {
    this.network().getPolicyClaimRecord()
  },
  methods:{
    ...mapActions('claim', ['setClaimDetail']),
    handleAddClaim(){
      let {order_no} = this.$route.query||{}
      this.setClaimDetail(null)
      this.$router.push({
        path:'/create/claim',
        query:{
          order_no
        }
      })
    },
    handleEditClaim(id){
      this.$router.push(`/claim/detail/${id}`)
    },
    network() {
      return {
        getPolicyClaimRecord: async (params) => {
          if(!this.order_no)
            return
          const { data } = await getPolicyClaimRecord({
            order_no:this.order_no,
          })
          // let {meta} = data
          this.tableData = data
          // this.pageConfig.total = meta.total
        },
      }
    },
  }
}
</script>

<style  lang="scss">
.flex-content{
  margin-bottom: 20px;
  font-size: 16px;
  font-weight: 600;

    .flex-cloumn:first-child{
      color: #000;
    }
    .flex-cloumn{
      display: flex;
      flex-direction: column;
      color: #4A5568;
      justify-content: space-between;
      .number{
        font-size: 28px;
        color: #718096;
        font-family: Inter;
        font-style: normal;
        font-weight: 400;
        line-height: 140%;
      }
    }
  .flex-button{
    align-self: self-start;
  }
}

</style>
