<template>
  <section>
    <v-dialog
      v-model="dialog"
      class="width-dialog-sm-and-down dialog-box"
      width="430"
    >
      <div class="dialog-container width-dialog-sm-and-down">
        <div class="dialog-header">
          <h2 v-if="uploadType!==3">Upload {{uploadType==2?'Cover Note':' Policy'}}</h2>
        </div>
        <div
          class="dialog-body"
        >
          <div
            class="transparent "
            :class="{'sent-content':uploadType!=3}">
            <el-form
              ref="form"
              :model="formData"
              :rules="rules"
            >
              <div  v-if="uploadType!=3">
                <div
                  class="form-item">
                  <span>{{uploadType==2?'Cover Note':'Policy'}} Number</span>
                  <el-form-item
                    ref="upload_no"
                    prop="upload_no"
                    :rules="rules.input">
                    <v-input
                      v-model="formData.upload_no"
                    ></v-input>
                  </el-form-item>
                </div>
                <div
                  v-if="uploadType!=2"
                  class="form-item">
                  <el-form-item
                    ref="policy_document_type"
                    prop="policy_document_type"
                    :rules="rules.select">
                    <v-radio-group
                      v-model="formData.policy_document_type"
                      :list="policyUploadTypeOptions"
                    ></v-radio-group>
                  </el-form-item>
                </div>
                <div
                  v-if="uploadType==2||uploadType==1&&formData.policy_document_type!=2"
                  class="form-item ">
                  <span>{{uploadType==2?'Cover Note':'Policy'}}</span>
                  <el-form-item
                    prop="upload_file_id"
                    :rules="rules.upload">
                    <file-container-card
                      :edit="true"
                      is-dialog
                      :fileData="formData.upload_file"
                      :otherData="{
                        idKey: 'upload_file_id',
                        urlKey: 'upload_file_url',
                        client_name:'upload_file_client_name',
                        nameKey:'upload_file'
                      }"
                      @view="handleView"
                      @delete="handlerDeleteFile"
                      @uploadSuccess="handlerUploadSuccess" />
                  </el-form-item>
                </div>
                <template v-if="uploadType==1&&formData.policy_document_type==2">
                  <div class="form-item " >
                    <span>CI</span>
                    <el-form-item
                      prop="policy_ci_document_id"
                      :rules="rules.upload">
                      <file-container-card
                        :edit="true"
                        is-dialog
                        :fileData="formData.policy_ci_document"
                        :otherData="{
                          idKey: 'policy_ci_document_id',
                          urlKey: 'policy_ci_document_url',
                          client_name:'policy_ci_document_client_name',
                          nameKey:'policy_ci_document'
                        }"
                        @view="handleView"
                        @delete="handlerDeleteFile"
                        @uploadSuccess="handlerUploadSuccess" />
                    </el-form-item>
                  </div>
                  <div class="form-item " >
                    <span>Schedule</span>
                    <el-form-item
                      prop="policy_schedule_document_id"
                      :rules="rules.upload">
                      <file-container-card
                        :edit="true"
                        is-dialog
                        :fileData="formData.policy_schedule_document"
                        :otherData="{
                          idKey: 'policy_schedule_document_id',
                          urlKey: 'policy_schedule_document_url',
                          client_name:'policy_schedule_document_client_name',
                          nameKey:'policy_schedule_document'
                        }"
                        @view="handleView"
                        @delete="handlerDeleteFile"
                        @uploadSuccess="handlerUploadSuccess" />
                    </el-form-item>
                  </div>
                </template>
              </div>
              <div v-else>
                <div
                  class="form-item">
                  <span class="black-color">Full Set Doc. Received?</span>
                  <div class="car-type-radio">
                    <el-form-item
                      ref="documents_received"
                      :rules="rules.select"
                      prop="documents_received">
                      <v-radio-group
                        v-model="formData.documents_received"
                        :border="true"
                        :list="yesNoOptions"
                      />
                    </el-form-item>
                  </div>
                </div>
              </div>
            </el-form>
          </div>

        </div>
        <div
          class="dialog-footer"
          align="right">
          <div class="btn-container">
            <v-button
              size="small"
              class="button-theme-block"
              @click="handleCancel">Cancel
            </v-button>
            <v-button
              size="small"
              type="primary"
              class="button-theme"
              @click="handlerSave">Confirm
            </v-button>
          </div>
        </div>
      </div>
    </v-dialog>
    <v-dialog
      v-model="isOpen"
      showClose
      class="view-dialog"
      fullscreen>
      <embed
        :src="fileUrl"
        width="100%"
        height="100%"
        type="application/pdf">
    </v-dialog>
  </section>
</template>
<script>
import MixinOptions from '@/mixins/options'
import {updateDoc} from '@/api/order'
import MixinRules from '@/mixins/rules'

export default {
  name: 'uploadCoverDialog',
  mixins: [MixinOptions,MixinRules],

  props:{

    form: {
      type: Object,
      default: () => ({})
    },
    orderNo:{
      type: [String,Number],
      default:'',//1// hire 2:issue
    },
    uploadType:{
      type: [String,Number],
      default:'',//1// hire 2:issue
    },
    value:{
      type: Boolean,
      default:false,//1// hire 2:issue
    },
    isReplace:{
      type: Boolean,
      default:false,//1// hire 2:issue
    },
  },
  data() {
    return {
      dialog:false,
      isSent:false,
      isOpen:false,
      date:'',
      formData:{
        // policy_no: '',
        // covernote_no: '',
        // policy_document_id: '',
        // policy_document_url:'',
        // covernote_document_id: '',
        // covernote_document_url:'',
        upload_no:'',
        upload_file:{},
        upload_file_id:'',
        documents_received:'',
        policy_document_type:'',
        policy_ci_document:{},
        policy_ci_document_id:'',
        policy_ci_document_url:'',
        policy_schedule_document:{},
        policy_schedule_document_id:'',
        policy_schedule_document_url:'',
      }
    }
  },
  computed:{
    fileUrl(){
      let {upload_file} = this.formData||{}
      let {url} = upload_file||{}
      return url||''
    },
  },
  watch:{
    value:{
      immediate:true,
      handler(val){

        if(val){
          let {new_policy_documents={},policy_document={},covernote_document={},documents_received,policy_no,covernote_no,policy_document_type} =this.form
          let {documents=[]} =new_policy_documents||{}

          if(this.uploadType==1){
            this.formData.upload_no  =policy_no
            this.formData.policy_document_type  =policy_document_type
            documents.some(item=>{
              if(item.name.indexOf('CI')!=-1){
                this.formData.policy_ci_document_id = item.file_id
                this.formData.policy_ci_document = item
              }
              if(item.name.indexOf('Schedule')!=-1){
                this.formData.policy_schedule_document_id = item.file_id
                this.formData.policy_schedule_document = item
              }
              if(item.name.indexOf('Policy')!=-1){
                this.formData.upload_file_id = item.file_id
                this.formData.upload_file = item
              }
            })
          }
          if(this.uploadType==2){
            this.formData.upload_no = covernote_no
            this.formData.upload_file = {}
            this.formData.upload_file_id =''
            if(covernote_document) {
              this.formData.upload_file_id = covernote_document.file_id
              this.formData.upload_file = covernote_document
            }

          }
          if(this.uploadType==3){
            this.formData.documents_received = documents_received
          }
        }
        this.dialog = val
      }
    },
    uploadType:{
      immediate:true,
      handler(val){
        // let {policy_document={},policy_ci_document={},covernote_document={},documents_received,policy_no,covernote_no,policy_document_type} =this.form


      }
    },
    // form: {
    //   deep: true,
    //   immediate: true,
    //   handler(val) {
    //     // let {payment} = val||{}
    //     // Object.keys(this.formData).some(key => {
    //     //   if (key in val) {
    //     //     this.formData[key] = val[key]
    //     //   }
    //     // })
    //     let {new_policy_documents={},policy_document={},covernote_document={},documents_received,policy_no,covernote_no,policy_document_type} =val
    //     let {documents=[]} =new_policy_documents||{}
    //     if(this.uploadType==1){
    //       this.formData.upload_no  =policy_no
    //       this.formData.policy_document_type  =policy_document_type
    //       if(policy_document) {
    //         this.formData.upload_file_id = policy_document.file_id
    //         this.formData.upload_file = policy_document
    //       }
    //     }
    //     if(this.uploadType==2){
    //       this.formData.upload_no = covernote_no
    //       if(covernote_document) {
    //         this.formData.upload_file_id = covernote_document.file_id
    //         this.formData.upload_file = covernote_document
    //       }
    //
    //     }
    //     if(this.uploadType==3){
    //       this.formData.documents_received = documents_received
    //     }
    //   }
    // }
  },
  methods: {
    handleView(){
      this.isOpen = true
    },
    // file-container 組件刪除文件
    handlerDeleteFile(otherData) {
      const { idKey, nameKey, urlKey,client_name } = otherData
      this.$set(this.formData, idKey, '')
      this.$set(this.formData, urlKey, '')
      this.$set(this.formData, nameKey, {})
      this.$set(this.formData, client_name, '')
    },
    handlerUploadSuccess({ data, result, otherData }) {
      const {  idKey,  urlKey,client_name,nameKey } = otherData
      const { file_id,  url,path } = data
      this.$set(this.formData, idKey, file_id)
      this.$set(this.formData, urlKey, url)
      this.$set(this.formData, client_name, path)
      this.$set(this.formData, nameKey, data)
      this.$refs['form'].clearValidate([idKey])
    },
    handlerSave(){
      this.$refs['form'].validate(valid => {
        if(valid) {
          this.network().updateDoc()
          this.$refs.form.resetFields()


        } else {
        }
      })

    },
    handleCancel(){
      // if(!this.isReplace){
      // this.formData.upload_no = ''
      // this.formData.upload_file ={thumb_url:'',url:''}
      // this.formData.upload_file_id=''
      // }
      this.$refs.form.resetFields()
      this.$emit('input',false)
    },
    network() {
      return {
        updateDoc: async (params) => {
          params = {...params}
          let {
            upload_no,upload_file_id, documents_received,policy_document_type,
            policy_schedule_document_id,policy_ci_document_id
          } = this.formData||{}
          if(this.uploadType==2){
            params.covernote_no = upload_no
            params.covernote_document_id = upload_file_id
          }
          if(this.uploadType==1){
            params.policy_no = upload_no
            params.policy_document_type  =policy_document_type
            if(policy_document_type==1){
              params.policy_document_id = upload_file_id
            }
            if(policy_document_type==2){
              params.policy_schedule_document_id = policy_schedule_document_id
              params.policy_ci_document_id = policy_ci_document_id
            }
          }
          if(this.uploadType==3){
            params.documents_received = documents_received
          }
          let { data } = await updateDoc({...params,order_no:this.orderNo})
          this.formData.upload_no = ''
          this.formData.upload_file ={thumb_url:'',url:''}
          this.formData.upload_file_id=''
          this.formData.policy_document_type=''
          this.$emit('refresh')
          this.$emit('input',false)
        },
      }
    }
  }
}
</script>
<style lang="scss" scoped>
.dialog-box{
  width: 430px!important;
  ::v-deep .el-dialog{
    width: 430px;
  }
  .dialog-container {
    padding: 30px;
    color: #000000;
    width: 430px;
    .dialog-header {
      display: flex;
      justify-content: space-between;
      align-items: center;
      //margin: 0 -15px;
      //padding: 30px 30px 0 30px;
      //   border-bottom: 1px solid #dddfe7;
      h1 {
        font-size: 24px;
      }
    }
    .dialog-body{
      .sent-content{
        margin: 20px 0;
        color: #718096;
        .el-checkbox{
          margin-right: 10px;
        }
      }
      .form-item{
        margin: 0 0px 30px 0px;
        span{
          font-family: 'Inter';
          font-style: normal;
          font-weight: 700;
          font-size: 14px;
          line-height: 150%;
          color: #2D3748;
        }
      }
    }

    .transparent{
      margin-bottom: 20px;
    }
  }
  .black-color{
    color: #2D3748!important;
  }
}
/deep/.upload-card {
  width: 200px;
  height: 134.55px;
}
.view-dialog{
  /deep/.el-dialog__body{
    overflow-y: hidden;
    height: 100vh;

  }
  /deep/.el-dialog{
    &.is-fullscreen{
      overflow-y: hidden;
      z-index: 99999;
    }
    .el-dialog__close {
      color: $primary;
      font-size: 48px;
      border: 3px solid $primary;
      border-radius: 50%;
      margin-top: 20px;
      margin-right: 20px;
      z-index: 99999;
    }
  }
}
</style>
